import { usePermissions, AppBar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { UserMenu } from "../profile/menu";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 0.8
  },
  appBar: {
    "& .RaAppBar-title": {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  }
});

export const MyAppBar = () => {
  usePermissions();
  const classes = useStyles();
  return (
    <>
      <AppBar
        color="secondary"
        className={classes.appBar}
        userMenu={<UserMenu />}
      ></AppBar>
    </>
  );
};
