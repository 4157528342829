import { FC } from "react";
import {
  Datagrid,
  Filter,
  List,
  ListProps,
  Pagination as RaPagination,
  PaginationProps,
  SelectField,
  SelectInput,
  TextField,
  TextInput} from "react-admin";

import { types } from "./constants";
import { TimestampField } from "../../components";

export const ListHosts: FC<ListProps> = props => (
  <List
    title="Hosts"
    filters={<HostFilter />}
    bulkActionButtons={false}
    perPage={15}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <SelectField source="type" choices={types} />
      <TimestampField label="Created" source="createdAt" />
    </Datagrid>
  </List>
);

const Pagination = (props: PaginationProps) => (
  <RaPagination rowsPerPageOptions={[15, 30, 60, 100]} {...props} />
);

const HostFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search.name" />
      <SelectInput label="Deployment" source="deployment" choices={types} />
    </Filter>
  );
};
