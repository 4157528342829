import {
  UserMenu as RaUserMenu,
  MenuItemLink,
  usePermissions,
  useLogout
} from "react-admin";
import { PowerOff, Settings } from "@material-ui/icons";
import { DownloadDump } from "../components/db-dump-btn";

export const UserMenu = (props: any) => {
  const { permissions: user } = usePermissions();

  return (
    <RaUserMenu {...props}>
      <DownloadDump />
      <MenuItemLink
        to={`/users/${user?.id}/edit?profile`}
        primaryText="Profile"
        leftIcon={<Settings />}
      />
      <LogoutButton />
    </RaUserMenu>
  );
};

const LogoutButton = () => {
  const logout = useLogout();
  return (
    <MenuItemLink
      leftIcon={<PowerOff />}
      to="/logout"
      primaryText="Logout"
      onClick={() => logout()}
    />
  );
};
