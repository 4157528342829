import {
    useNotify,
    FormWithRedirect,
    SaveButton,
    Button as RaButton,
    FormDataConsumer,
    ReferenceInput,
    required,
    useRefresh,
    SelectInput
  } from "react-admin";
  import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions  } from "@material-ui/core";
  import { Cancel, EmojiSymbols } from "@material-ui/icons";
  import { useState } from "react";

  import { updateTag } from "./operations";
  import { useRequestState } from "../../hooks/request";

  export const DeployButton = (props: { id: string}) => {
    const [visible, setVisible] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();
    const host = useRequestState(updateTag, []);

    function toggleVisibility() {
      setVisible(d => !d);
    }

    function handleSubmit(values: any) {
        console.log(values);
      host
        .fetch(props.id, values)
        .then(() => {
          toggleVisibility();
          notify("Instance deployed successfully");
          refresh();
        })
        .catch(error => {
          notify(error.response?.data?.message || error.message, "error");
        });
    }

    return (
      <>
        <RaButton
          variant="outlined"
          label="Deploy"
          onClick={toggleVisibility}
        >
          <EmojiSymbols />
        </RaButton>
        <Dialog
          fullWidth
          open={visible}
          onClose={toggleVisibility}
          aria-label="Deploy"
        >
          <DialogTitle>Select Deployment Tag</DialogTitle>

          <FormWithRedirect
            resource="hosts"
            save={handleSubmit}
            render={({ handleSubmitWithRedirect }) => {
              return (
                <>
                  <DialogContent>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <>
                          <ReferenceInput
                            label="Tag"
                            source="version"
                            reference="tags"
                            fullWidth
                            validate={[required()]}
                            variant="outlined"
                          >
                            <SelectInput />
                          </ReferenceInput>
                        </>
                      )}
                    </FormDataConsumer>
                  </DialogContent>
                  <DialogActions>
                    <SaveButton
                      label="Deploy"
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={host.loading}
                      disabled={host.loading}
                    />
                    <RaButton label="ra.action.cancel" onClick={toggleVisibility}>
                      <Cancel />
                    </RaButton>
                  </DialogActions>
                </>
              );
            }}
          />
        </Dialog>
      </>
    );
  };
