import { Group } from "@material-ui/icons";
import { ResourceProps } from "react-admin";
import { CreateUser, EditUser } from "./create-edit";
import { ListUsers } from "./list";

export const users: ResourceProps = {
  name: "users",
  options: {
    label: "Users"
  },
  list: ListUsers,
  create: CreateUser,
  edit: EditUser,
  icon: Group
};

export const profileEdit = {
  name: "users",
  options: {
    label: "Users"
  },
  edit: EditUser
};
