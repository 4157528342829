import { useState } from "react";
import {
  BooleanInput,
  email,
  FormDataConsumer,
  regex,
  required,
  SelectInput,
  TextInput
} from "react-admin";
import { getTimezonesForCountry } from "countries-and-timezones";

import { countries } from "../constants";

export function CompanyPanel() {
  const [country, setCountry] = useState("UG");

  const validateEmail = [email()];
  const validateUrl = [
    regex(
      /^(https?:\/\/)?(www\.)?([-a-zA-Z0-9]{1,256}\.){1,256}[a-z]{2,6}([-a-zA-Z0-9@:%_.~#?&=]*)/,
      "Must be a valid url"
    )
  ];

  return (
    <>
      <TextInput
        source="company.name"
        label="Name"
        fullWidth
        validate={[required()]}
      />
      <TextInput
        source="company.email"
        label="E-mail"
        fullWidth
        validate={validateEmail}
      />
      <TextInput
        source="company.contact"
        label="Contact"
        fullWidth
        validate={[required()]}
      />

      <SelectInput
        source="company.country"
        label="Country"
        choices={countries}
        onChange={e => setCountry(e.target.value)}
        fullWidth
        validate={[required()]}
      />

      <SelectInput
        source="company.timezone"
        label="Timezone"
        choices={
          getTimezonesForCountry(country)?.map(zone => {
            return { id: zone.name, name: zone.name };
          }) || []
        }
        fullWidth
        validate={[required()]}
      />
      <TextInput
        source="company.website"
        label="Website"
        fullWidth
        validate={validateUrl}
      />
      <TextInput
        source="company.location"
        label="Location"
        fullWidth
        validate={[required()]}
      />
      <TextInput
        source="company.googleMapsUrl"
        label="Google Maps URL"
        fullWidth
        validate={validateUrl}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.company?.active}
            label="Active"
            source="company.active"
          />
        )}
      </FormDataConsumer>
    </>
  );
}
