import axios, { AxiosInstance } from "axios";

import { backendUrl } from "../config";

function httpClient(baseURL: string): AxiosInstance {
  const baseClient = axios.create({ baseURL });

  baseClient.interceptors.request.use(request => {
    const accessToken = localStorage.getItem("token");
    return {
      ...request,
      headers: {
        ...request.headers,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*"
      }
    };
  });

  return baseClient;
}

export const baseHttpClient: AxiosInstance = httpClient(backendUrl);
