import { Admin, Resource, RouteWithoutLayout } from "react-admin";
import * as moment from "moment";
import { createBrowserHistory } from "history";

import { getAuthProvider, getRestDataProvider } from "./providers";
import { getResources } from "./resources";
import { theme } from "./common";
import { User } from "./resources/users/constants";
import { ForgotPassword } from "./auth/forgot";
import { ResetPassword } from "./auth/reset";
import { LoginPage } from "./auth/login";
import { Dashboard } from "./dashboard";
import { MyLayout } from "./layout";

const history = createBrowserHistory();

function App() {
  const dataProvider = getRestDataProvider();
  const authProvider = getAuthProvider();

  moment.relativeTimeRounding(Math.floor);

  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      title="Medx Control Center"
      dashboard={Dashboard}
      layout={MyLayout}
      customRoutes={[
        <RouteWithoutLayout
          path="/forgot"
          component={ForgotPassword}
          noLayout
        />,

        <RouteWithoutLayout
          path="/reset/:token"
          component={ResetPassword}
          noLayout
        />
      ]}
      history={history}
    >
      {(user: User) =>
        getResources(user).map(resource => <Resource {...resource} />)
      }
    </Admin>
  );
}

export default App;
