import { Divider } from "@material-ui/core";
import {
  BooleanInput,
  FormDataConsumer,
  required,
  SelectInput,
  TextInput,
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput
} from "react-admin";

import { modes, billingModes, BillingMode } from "../constants";

export function SettingsPanel() {
  return (
    <>
      <SelectInput
        source="settings.mode"
        label="Mode"
        choices={modes}
        fullWidth
        validate={[required()]}
      />
      <ReferenceInput
        label="Host"
        source="hostId"
        reference="hosts"
        perPage={10}
        fullWidth
        validate={[required()]}
        variant="outlined"
      >
        <SelectInput />
      </ReferenceInput>
      <TextInput
        source="settings.subdomain"
        label="Subdomain"
        fullWidth
        validate={[required()]}
      />
      <TextInput
        source="settings.publicSubdomain"
        label="Public Subdomain"
        fullWidth
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={
              formData?.settings?.financialManagement?.enabled || false
            }
            label="Financial Management"
            source="settings.financialManagement.enabled"
          />
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.financialManagement?.enabled && (
            <BooleanInput
              defaultValue={
                formData?.settings?.financialManagement?.orderlessRequest ||
                false
              }
              source="settings.financialManagement.orderlessRequest"
              label="Orderless Request"
              fullWidth
            />
          )
        }
      </FormDataConsumer>
      <Divider />
      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={
              formData?.settings?.inventoryManagement?.enabled || false
            }
            label="Inventory Management"
            source="settings.inventoryManagement.enabled"
          />
        )}
      </FormDataConsumer>
      <Divider />
      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.settings?.sms?.enabled || false}
            label="SMS"
            source="settings.sms.enabled"
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.sms?.enabled && (
            <CheckboxGroupInput
              source="settings.sms.providers"
              label="SMS Providers"
              choices={[{ id: "africastalking", name: "africastalking" }]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.sms?.enabled &&
          formData?.settings?.sms?.providers?.toString() ===
            "africastalking" && (
            <TextInput
              source="settings.sms.africasTalkingUsername"
              label="Africas Talking Username"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.sms?.enabled &&
          formData?.settings?.sms?.providers?.toString() ===
            "africastalking" && (
            <TextInput
              source="settings.sms.africasTalkingKey"
              label="Africas Talking Key"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <Divider />

      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.settings?.whatsapp?.enabled || false}
            label="WhatsApp"
            source="settings.whatsapp.enabled"
          />
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.whatsapp?.enabled && (
            <CheckboxGroupInput
              source="settings.whatsapp.providers"
              label="WhatsApp Providers"
              choices={[{ id: "wati", name: "wati" }]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.whatsapp?.enabled &&
          formData?.settings?.whatsapp?.providers?.toString() === "wati" && (
            <TextInput
              source="settings.whatsapp.watiKey"
              label="Wati Key"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.whatsapp?.enabled && (
            <TextInput
              source="settings.whatsapp.group"
              label="Whatsapp Group"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <Divider />

      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.settings?.qualityControl?.enabled || false}
            label="Quality Control"
            source="settings.qualityControl.enabled"
          />
        )}
      </FormDataConsumer>
      <Divider />

      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.settings?.onlinePayments?.enabled || false}
            label="Online Payments"
            source="settings.onlinePayments.enabled"
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.onlinePayments?.enabled && (
            <CheckboxGroupInput
              source="settings.onlinePayments.providers"
              label="Online Payment Providers"
              choices={[
                { id: "flutterwave", name: "Flutterwave" },
                { id: "gt-pay", name: "GT Pay" },
                { id: "silicon-pay", name: "Silicon Pay" }
              ]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.onlinePayments?.enabled &&
          formData?.settings?.onlinePayments?.providers?.includes(
            "flutterwave"
          ) && (
            <TextInput
              source="settings.onlinePayments.flutterwavePublicKey"
              label="Flutterwave Public Key"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.onlinePayments?.enabled &&
          formData?.settings?.onlinePayments?.providers?.includes(
            "flutterwave"
          ) && (
            <TextInput
              source="settings.onlinePayments.flutterwaveSecretKey"
              label="Flutterwave Secret Key"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.onlinePayments?.enabled &&
          formData?.settings?.onlinePayments?.providers?.includes("gt-pay") && (
            <TextInput
              source="settings.onlinePayments.gtPayKey"
              label="GT Pay Key"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.onlinePayments?.enabled &&
          formData?.settings?.onlinePayments?.providers?.includes(
            "silicon-pay"
          ) && (
            <TextInput
              source="settings.onlinePayments.siliconPayKey"
              label="Silicon Pay Key"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <Divider />

      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.settings?.surveillance?.enabled || false}
            label="Surveillance"
            source="settings.surveillance.enabled"
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.surveillance?.enabled && (
            <CheckboxGroupInput
              source="settings.surveillance.providers"
              label="Surveillance Providers"
              choices={[
                { id: "rds", name: "RDS" },
                { id: "vts", name: "VTS" }
              ]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.surveillance?.enabled &&
          formData?.settings?.surveillance?.providers?.includes("rds") && (
            <TextInput
              source="settings.surveillance.rdsEacLabId"
              label="RDS EAC LabId"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.surveillance?.enabled &&
          formData?.settings?.surveillance?.providers?.includes("rds") && (
            <TextInput
              source="settings.surveillance.rdsLisId"
              label="RDS LIS Id"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.surveillance?.enabled &&
          formData?.settings?.surveillance?.providers?.includes("vts") && (
            <TextInput
              source="settings.surveillance.vouchUrl"
              label="Vouch URL"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.surveillance?.enabled &&
          formData?.settings?.surveillance?.providers?.includes("vts") && (
            <TextInput
              source="settings.surveillance.vouchToken"
              label="Vouch Token"
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <Divider />

      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            defaultValue={formData?.settings?.billing?.enabled || false}
            label="Billing"
            source="settings.billing.enabled"
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.billing?.enabled && (
            <SelectInput
              source="settings.billing.mode"
              label="Billing Mode"
              choices={billingModes}
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData?.settings?.billing?.enabled &&
          (formData?.settings?.billing?.mode as BillingMode) === "credits" ? (
            <NumberInput
              source="settings.billing.minCredits"
              label="Minimum Credits"
              fullWidth
              validate={[required()]}
            />
          ) : null
        }
      </FormDataConsumer>
    </>
  );
}
