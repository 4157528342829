import { FC } from "react";

import { List, ListProps, Pagination, Filter, SelectInput } from "react-admin";
import { AvatarField } from "../../components";
import { statuses } from "./constants";
import { MobileListGrid } from "../../components/mobile-list-grid";
import { Box, Chip, Typography } from "@material-ui/core";

export const ListInstances: FC<ListProps> = props => (
  <List
    title="Instances"
    filters={<InstanceFilter />}
    filterDefaultValues={{ "company.active": true }}
    bulkActionButtons={false}
    perPage={15}
    pagination={<Pagination rowsPerPageOptions={[15, 30, 60, 100]} />}
    sort={{ field: "instanceId", order: "ASC" }}
    {...props}
  >
    <MobileListGrid
      link={record => `/instances/${record.id}/show`}
      metadata={record => <></>}
      content={record => (
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Chip variant="outlined" size="small" label={record.instanceId} />
          <Box marginRight={1} />
          <Typography variant="body1">{record.company.name}</Typography>
        </Box>
      )}
      right={record => (
        <AvatarField
          record={record}
          type="instance"
          source="icon"
          label="Icon"
        />
      )}
    />
  </List>
);

const InstanceFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <SelectInput label="Status" source="company.active" choices={statuses} />
    </Filter>
  );
};
