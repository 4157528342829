import { baseHttpClient } from "../../providers";

interface Response {
  message: string;
}

export function requestResetPasswordLink(email: string): Promise<Response> {
  return baseHttpClient
    .post<Response>("/auth/reset-password", { email })
    .then(response => response.data);
}

export function resetPassword(
  token: string,
  password: string
): Promise<Response> {
  return baseHttpClient
    .post<Response>("/auth/new-password", { token, password })
    .then(response => response.data);
}
