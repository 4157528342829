import { path } from "ramda";
import { Avatar } from "@material-ui/core";
import Person from "@material-ui/icons/AccountCircle";
import Business from "@material-ui/icons/Business";

export const AvatarField = ({ type, record = {}, source, size = 48 }: any) => {
  const image = path(source.split("."), record);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {image ? (
        <Avatar
          src={getCloudImageLink(image, size)}
          style={size ? { width: size, height: size } : undefined}
        />
      ) : type === "instance" ? (
        <Business color="primary" style={{ fontSize: size }} />
      ) : (
        <Person color="primary" style={{ fontSize: size }} />
      )}
    </div>
  );
};

function getCloudImageLink(image: any, size: number) {
  return `${image}?width=${size * 2}&height=${size *
    2}&func=face&face_margin=50`;
}
