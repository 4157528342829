import * as React from "react";
import { useLogin, useNotify } from "react-admin";
import { Typography, Button, TextField, Box } from "@material-ui/core";
import { useStyles } from "./style";
import { Link } from "react-router-dom";
import { AuthLayout } from "./layout";

export const LoginPage = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = useLogin();
  const notify = useNotify();

  React.useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    login({ email, password }).catch(error => {
      notify(error.response?.data?.message || error.message, "error");
    });
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <Box marginY="1em">
          <TextField
            autoFocus
            name="email"
            label={"Email"}
            value={email}
            fullWidth
            onChange={event => setEmail(event.target.value)}
          />
        </Box>

        <Box marginY="1em">
          <TextField
            name="password"
            label={"Password"}
            type="password"
            value={password}
            fullWidth
            onChange={event => setPassword(event.target.value)}
          />
        </Box>

        <Button variant="contained" type="submit" color="primary" fullWidth>
          Sign In
        </Button>
      </form>

      <Box marginTop="1em" />

      <Typography
        component={Link}
        className={classes.link}
        to="/forgot"
        align="center"
        color="secondary"
      >
        Forgot Password?
      </Typography>
    </AuthLayout>
  );
};
