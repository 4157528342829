import React from "react";

type ArgType =
  | [unknown?]
  | [unknown?, unknown?]
  | [unknown?, unknown?, unknown?]
  | [unknown?, unknown?, unknown?, unknown?];

type AsyncFunc<A extends ArgType, V> = (...a: A) => Promise<V>;

interface AsyncValue<A extends ArgType, V> {
  loading: boolean;
  value: V;
  fetch(...a: A): Promise<V>;
  clear(): void;
}

export function useRequestState<A extends ArgType, V>(
  request: AsyncFunc<A, V>,
  defaultValue: V
): AsyncValue<A, V> {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<V>(defaultValue);

  function fetch(...a: A) {
    setLoading(true);
    return request(...a)
      .then(v => {
        setValue(v);
        setLoading(false);
        return v;
      })
      .catch(error => {
        setLoading(false);
        return Promise.reject(error);
      });
  }

  function clear() {
    setValue(defaultValue);
  }

  return { loading, value, fetch, clear };
}
