import {
  CreateParams,
  GetListParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateParams,
  DeleteParams,
  LegacyDataProvider,
  HttpError
} from "react-admin";
import { stringify } from "qs";
import { baseHttpClient } from "./client";

const restAction: Record<string, any> = {
  GET_LIST: getList,
  GET_ONE: getOne,
  GET_MANY: getMany,
  GET_MANY_REFERENCE: getManyReference,
  CREATE: create,
  UPDATE: update,
  UPDATE_MANY: updateMany,
  DELETE: deleteOne,
  DELETE_MANY: deleteMany
};

export function getRestDataProvider(): LegacyDataProvider {
  return (type: string, resource, params) => {
    const getConfig = restAction[type];

    if (!getConfig) {
      return Promise.reject(new HttpError("Unsupported action", 400));
    }

    const url = `/portal/${resource}`;

    const config = getConfig(url, params);

    return baseHttpClient(config)
      .then(response => response.data)
      .catch(error => {
        if (error.response?.data?.message) {
          return Promise.reject(
            new HttpError(
              error.response.data.message,
              error.response.status,
              error.response.data
            )
          );
        }

        return Promise.reject(new HttpError(error.message, error.status));
      });
  };
}

function getList(url: string, params: GetListParams) {
  return {
    method: "GET",
    url: `${url}?${stringify({
      ...params.pagination,
      ...params.sort,
      filter: params.filter
    })}`
  };
}

function getOne(url: string, params: GetOneParams) {
  return { method: "GET", url: `${url}/${params.id}` };
}

function getMany(url: string, params: any) {
  const { ids } = params;

  return {
    method: "GET",
    url: `${url}?${stringify({ ids })}`
  };
}

function getManyReference(url: string, params: GetManyReferenceParams) {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query = {
    field,
    order,
    page,
    perPage,
    filter: {
      ...params.filter,
      [params.target]: params.id
    }
  };

  return { method: "GET", url: `${url}?${stringify(query)}` };
}

function create(url: string, params: CreateParams) {
  return { method: "POST", url, data: params.data };
}

function update(url: string, params: UpdateParams) {
  return { method: "PUT", url: `${url}/${params.id}`, data: params.data };
}

function updateMany(url: string, params: any) {
  const { ids, data } = params;
  return { method: "PUT", url: `${url}?${stringify({ ids })}`, data };
}

function deleteOne(url: string, params: DeleteParams) {
  return { method: "DELETE", url: `${url}/${params.id}` };
}

function deleteMany(url: string, params: any) {
  const { ids } = params;
  return { method: "DELETE", url: `${url}?${stringify({ ids })}` };
}
