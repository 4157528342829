import { Grid } from "@material-ui/core";
import { FormDataConsumer, required, TextInput } from "react-admin";

import { ImageInput, CameraInput } from "../../../components/cameraInput";

export function ThemePanel() {
  return (
    <>
      <Grid item xs={6} sm={4}>
        <FormDataConsumer>
          {() => {
            return (
              <CameraInput source="icon" label="icon" validate={[required()]} />
            );
          }}
        </FormDataConsumer>
      </Grid>

      <FormDataConsumer>
        {() => {
          return (
            <ImageInput
              source="logo"
              label="logo"
              fullWidth
              validate={[required()]}
            />
          );
        }}
      </FormDataConsumer>

      <TextInput
        source="theme.palette.primary"
        label="Primary Color"
        fullWidth
      />

      <TextInput
        source="theme.palette.secondary"
        label="Secondary Color"
        fullWidth
      />
    </>
  );
}
