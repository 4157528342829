import { createTheme } from "@material-ui/core";

export const primaryColor = "#d05286";
export const secondaryColor = "#572345";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      light: "#5f5fc4",
      main: secondaryColor,
      dark: "#001064",
      contrastText: "#fff"
    },
    background: {
      default: "#fcfcfe"
    },
    type: "light"
  },
  shape: {
    borderRadius: 10
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box"
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: primaryColor,
        boxShadow: "none"
      }
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit"
        }
      }
    },
    MuiAppBar: {
      colorSecondary: {
        border: "none"
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5"
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7"
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        border: "none"
      }
    }
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true
    }
  }
});
