import {
  IconButton,
  CircularProgress,
  Tooltip,
  Button
} from "@material-ui/core";
import { refreshView, showNotification } from "react-admin";
import { connect } from "react-redux";
import { useRequestState } from "../hooks/request";

const ActionButtonPresenter = ({
  title,
  handler,
  success,
  refreshView: refresh,
  showNotification: notify,
  component = "icon-button",
  children
}: any) => {
  const action = useRequestState(handler, "");

  function handleAction() {
    action
      .fetch()
      .then((data: any) => {
        refresh();
        if (typeof success === "function") return notify(success(data));

        return success && notify(success);
      })
      .catch((error: any) => {
        notify(error?.response?.data?.message || error.message);
      });
  }

  if (component === "button") {
    return (
      <Tooltip title={title}>
        <Button variant="outlined" onClick={handleAction}>
          {action.loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            children
          )}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={title}>
      <IconButton color="primary" onClick={handleAction}>
        {action.loading ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          children
        )}
      </IconButton>
    </Tooltip>
  );
};

export const ActionButton = connect(null, {
  refreshView,
  showNotification
})(ActionButtonPresenter);
