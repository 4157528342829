export interface Host {
    id: string;
    name: string;
    instanceId: string;
    dockerHost: string;
    labxpertHost: string;
    type: HostType;
    active: boolean;
  }

  export type HostType = "cloud" | "on prem";

  export const cloudType: HostType = "cloud";
  export const onPremType: HostType = "on prem";

export const types: {id: HostType, name: string}[] = [
    {id: cloudType, name: "Cloud"},
    {id:onPremType, name: "On Prem"}];
