import * as React from "react";
import { Notification } from "react-admin";
import { Paper, Typography, Box } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../common/theme";
import { useStyles } from "./style";

// tslint:disable-next-line:no-var-requires
const { version } = require("../../package.json");

export const AuthLayout: React.FC = props => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box
        className={classes.main}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="/logo.png"
          alt="Logo"
          height="160"
        />
        <Typography variant="h6">Medx International</Typography>
        <Typography variant="subtitle2">Lab Control Center</Typography>

        <Box paddingTop={"16px"} />

        <Paper className={classes.paper}>{props.children}</Paper>

        <Typography
          variant="caption"
          className={classes.link}
          align="center"
          color="primary"
        >
          v{version}
        </Typography>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};
