import { FC } from "react";
import {
  Datagrid,
  EmailField,
  List,
  ListProps,
  TextField,
  Pagination as RaPagination,
  PaginationProps,
  Filter,
  TextInput,
  SelectInput,
  SelectField
} from "react-admin";

import { AvatarField, TimestampField } from "../../components";
import { roles } from "./constants";

export const ListUsers: FC<ListProps> = props => (
  <List
    title="Users"
    filters={<UserFilter />}
    bulkActionButtons={false}
    perPage={15}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <AvatarField source="image" label="" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phone" />
      <SelectField source="role" choices={roles} />
      <TimestampField label="Created" source="createdAt" />
    </Datagrid>
  </List>
);

const Pagination = (props: PaginationProps) => (
  <RaPagination rowsPerPageOptions={[15, 30, 60, 100]} {...props} />
);

const UserFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search.name" />
      <SelectInput label="Role" source="role" choices={roles} />
    </Filter>
  );
};
