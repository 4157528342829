import React from "react";
import moment from "moment";
import { Typography, Tooltip, TypographyProps } from "@material-ui/core";
import { FieldProps } from "react-admin";

export const TimestampField: React.FC<FieldProps & {
  prefix?: boolean;
  color?: TypographyProps["color"];
  showTime?: boolean;
}> = ({
  record,
  source,
  prefix = true,
  showTime = false,
  color = "initial"
}) => {
  const timestamp = record && source && record[source];

  if (!timestamp) {
    return <Typography variant="caption" />;
  }

  const fullDate = moment(timestamp).format("Do MMMM YYYY [at] HH:mm:ss ");

  if (showTime) {
    return (
      <Typography variant="caption" color={color}>
        {fullDate}
      </Typography>
    );
  }

  return (
    <Tooltip title={fullDate}>
      <Typography variant="caption" color={color}>
        {moment(timestamp).fromNow(!prefix)}
      </Typography>
    </Tooltip>
  );
};
