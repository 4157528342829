declare const window: any;

const env = window.env ? window.env : process.env;

export interface Config {
  backendUrl: string;
  publicUrl: string;
}

export const config: Config = {
  backendUrl: env.BACKEND_URL as string,
  publicUrl: env.REACT_APP_PUBLIC_URL as string
};
