export interface User {
  id: string;
  name: string;
  avatar: string;
  role: UserRole;
}

export type UserRole = "admin" | "normal-user";

export type Gender =
  | { id: "male"; name: "Male" }
  | { id: "female"; name: "Female" };

export const adminRole: UserRole = "admin";
export const normalUserRole: UserRole = "normal-user";

export const roles: { id: UserRole; name: string }[] = [
  { id: adminRole, name: "Administrator" },
  { id: normalUserRole, name: "Normal User" }
];
