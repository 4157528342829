import { Avatar, Typography, Grid } from "@material-ui/core";
import { Camera, Photo } from "@material-ui/icons";
import { addField } from "react-admin";
import * as Compress from "client-compress";

const compressor = new Compress({ quality: 0.6 });

export const CameraInput = addField(
  ({
    input,
    label = "Profile Photo",
    meta: { touched, error } = { touched: false, error: "" }
  }: any) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e: any) {
      compressor
        .compress(Array.from(e.target.files))
        .then(([avatar]: any) => {
          return Compress.blobToBase64(avatar.photo.data);
        })
        .then((base64Url: any) => {
          input.onChange(base64Url);
        });
    }

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid
          style={{ display: "flex" }}
          alignItems="center"
          direction="column"
          item
          xs={12}
        >
          {input.value ? (
            <Avatar
              src={input.value}
              style={{ width: 240, height: 240 }}
              onClick={onCapture}
            />
          ) : (
            <Avatar style={{ width: 240, height: 240 }} onClick={onCapture}>
              <Camera style={{ fontSize: 64 }} />
            </Avatar>
          )}
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

export const ImageInput = addField(
  ({
    input,
    meta: { touched, error } = { touched: false, error: "" },
    label,
    ...all
  }) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e: any) {
      compressor
        .compress(Array.from(e.target.files))
        .then(([avatar]: any) => {
          return Compress.blobToBase64(avatar.photo.data);
        })
        .then((base64Url: any) => {
          input.onChange(base64Url);
        });
    }

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid style={{ display: "flex" }} direction="column" item xs={12}>
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
          {input.value ? (
            <Avatar
              src={input.value}
              style={{ width: 600, height: 240 }}
              onClick={onCapture}
              variant="square"
            />
          ) : (
            <Avatar
              style={{ width: 600, height: 240 }}
              onClick={onCapture}
              variant="square"
            >
              <Photo style={{ fontSize: 64 }} />
            </Avatar>
          )}
        </Grid>
      </Grid>
    );
  }
);
