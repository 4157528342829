import React from "react";
import { backendUrl } from "../config";
import { CloudDownload } from "@material-ui/icons";
import { MenuItemLink } from "react-admin";

export const DownloadDump = () => {
  function handleDownload(e: any) {
    e.preventDefault();

    window.open(
      `${backendUrl}/portal/dumpdb?access_token=${localStorage.getItem(
        "token"
      )}`
    );
  }

  return (
    <MenuItemLink
      leftIcon={<CloudDownload />}
      to="/download-dump"
      primaryText="Download Dump"
      onClick={handleDownload}
    />
  );
};
