import React from "react";
import { DatagridProps, Record } from "react-admin";
import { Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

interface Props {
  metadata(record: Record): React.ReactElement;
  content(record: Record): React.ReactElement;
  right(record: Record): React.ReactElement;
  link(record: Record): string;
}

export const MobileListGrid: React.FC<DatagridProps & Props> = ({
  ids = [],
  data = {},
  metadata,
  content,
  right,
  link
}) => (
  <>
    {ids.map(id => {
      const record = data[id];
      const Anchor: React.FC = props =>
        link ? (
          <Link to={link(record)} style={{ textDecoration: "none" }}>
            {props.children}
          </Link>
        ) : (
          <>{props.children}</>
        );
      return (
        <Anchor key={id}>
          <Paper style={{ padding: 16 }}>
            <Grid container direction="column" spacing={1}>
              <Grid item>{metadata(record)}</Grid>
              <Grid item>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid item>{content(record)}</Grid>
                  {right && <Grid item>{right(record)}</Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Anchor>
      );
    })}
  </>
);
