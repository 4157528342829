import { ResourceProps } from "react-admin";

import { users } from "./users";
import { instances } from "./instances";
import { hosts } from "./hosts";

export const adminResources: ResourceProps[] = [
  users,
  instances,
  hosts,
  { name: "tags" }
];

export const normalUserResources: ResourceProps[] = [];
