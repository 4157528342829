import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  SelectInput,
  required,
  ResourceComponentProps,
  ResourceComponentPropsWithId
} from "react-admin";

import { CameraInput } from "../../components";
import { roles } from "./constants";

export const CreateUser = (props: any) => {
  return (
    <Create {...props}>
      <Form {...props} />
    </Create>
  );
};

export const EditUser = (props: ResourceComponentPropsWithId) => {
  return (
    <Edit title={<Title />} {...props}>
      <Form {...props} />
    </Edit>
  );
};

const Title = ({ record }: any) => record && `${record.name}`;

export const Form = ({ permissions, ...props }: ResourceComponentProps) => {
  return (
    <SimpleForm {...props} redirect="list">
      <FormDataConsumer>
        {({ formData }) =>
          permissions?.id === formData?.id && <CameraInput source="avatar" />
        }
      </FormDataConsumer>

      <TextInput source="name" fullWidth validate={[required()]} />

      <TextInput source="email" fullWidth validate={[required()]} />

      <TextInput source="phone" fullWidth />

      <SelectInput
        source="role"
        choices={roles}
        fullWidth
        validate={[required()]}
      />
    </SimpleForm>
  );
};
