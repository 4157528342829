import {
  Create,
  Edit,
  ResourceComponentProps,
  ResourceComponentPropsWithId,
  SaveButton,
  Toolbar,
  TabbedForm,
  FormTab
} from "react-admin";

import { CompanyPanel } from "./panels/company";
import { SettingsPanel } from "./panels/settings";
import { ThemePanel } from "./panels/theme";

export const CreateInstance = (props: any) => {
  return (
    <Create title="Create Instance" {...props}>
      <Form {...props} />
    </Create>
  );
};

export const EditInstance = (props: ResourceComponentPropsWithId) => {
  return (
    <Edit title={<Title />} {...props}>
      <Form {...props} />
    </Edit>
  );
};

const Title = ({ record }: any) => record && `${record.company.name}`;

export const Form = ({ permissions, ...props }: ResourceComponentProps) => {
  return (
    <TabbedForm {...props} toolbar={<EditInstanceToolbar />} redirect="list">
      <FormTab label="company">
        <CompanyPanel />
      </FormTab>
      <FormTab label="theme">
        <ThemePanel />
      </FormTab>
      <FormTab label="settings">
        <SettingsPanel />
      </FormTab>
    </TabbedForm>
  );
};

const EditInstanceToolbar = (props: ResourceComponentPropsWithId) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
