import { AuthProvider } from "react-admin";
import jwtDecode from "jwt-decode";

import { baseHttpClient } from "./client";
import { User } from "../sdk/user";

export function getAuthProvider(): AuthProvider {
  return { login, logout, checkAuth, checkError, getPermissions, getIdentity };
}

function checkAuth(_params: any) {
  const token = localStorage.getItem("token");

  if (!token) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function checkError(error: any) {
  if (error.status === 401) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function login(params: any) {
  const { email, password } = params;
  return baseHttpClient
    .post(`/auth/login`, { email, password })
    .then(response => response.data)
    .then(({ token }) => {
      const user = jwtDecode<User>(token ? token : "");
      localStorage.setItem("token", token);
      localStorage.setItem("permission", user.role);
    });
}

function logout(_params: any) {
  localStorage.removeItem("token");
  return Promise.resolve();
}

function getIdentity() {
  const user = getUser();

  if (!user) {
    return Promise.reject();
  }

  const { id, name, avatar } = user;

  const [fullName] = name.split(/\s+/);

  return Promise.resolve({ id, fullName, avatar });
}

function getPermissions() {
  const user = getUser();
  const isResetOrForgot = /(forgot|reset)/.test(window.location.pathname);

  if (!user && !isResetOrForgot) {
    return Promise.reject({});
  }

  return Promise.resolve(user || {});
}

function getUser() {
  const token = localStorage.getItem("token");

  if (!token) return;

  const user = jwtDecode<User>(token);

  return user;
}
