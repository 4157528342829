import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    display: "block"
  },
  main: {
    // backgroundColor: theme.palette.secondary.main,
    height: "100vh",
    padding: 16,
    boxSizing: "border-box"
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8
  }
});
