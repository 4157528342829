import { GroupWork } from "@material-ui/icons";
import { ResourceProps } from "react-admin";

import { CreateInstance, EditInstance } from "./create-edit";
import { ListInstances } from "./list";
import { ShowInstance } from "./show";

export const instances: ResourceProps = {
  name: "instances",
  options: {
    label: "Instances"
  },
  create: CreateInstance,
  edit: EditInstance,
  list: ListInstances,
  show: ShowInstance,
  icon: GroupWork
};
