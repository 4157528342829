import { ResourceProps } from "react-admin";
import { adminResources, normalUserResources } from "./roleResources";
import { User, UserRole } from "./users/constants";

export function getResources(user: User) {
  return resourceMapper[user.role] || [];
}

const resourceMapper: Record<UserRole, ResourceProps[]> = {
  admin: adminResources,
  "normal-user": normalUserResources
};
