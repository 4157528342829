import {
  Typography,
  Breadcrumbs as RaBreadcrumbs,
  Box
} from "@material-ui/core";
import { Link } from "react-router-dom";

export const Breadcrumbs = (props: any) => {
  return (
    <Box padding="8px 0">
      <RaBreadcrumbs aria-label="breadcrumb">
        {props.items.map((item: any, index: number) => {
          if (item.pathname) {
            return (
              <Link
                key={index}
                color="inherit"
                to={{
                  pathname: item.pathname
                }}
              >
                <Typography color="textSecondary">{item.name}</Typography>
              </Link>
            );
          }

          return (
            <Typography key={index} color="textPrimary">
              {item.name}
            </Typography>
          );
        })}
      </RaBreadcrumbs>
    </Box>
  );
};
