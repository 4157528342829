import { Cloud } from "@material-ui/icons";
import { ResourceProps } from "react-admin";
import { CreateHost, EditHost } from "./create-edit";
import { ListHosts } from "./list";

export const hosts: ResourceProps = {
  name: "hosts",
  options: {
    label: "Hosts"
  },
  list: ListHosts,
  create: CreateHost,
  edit: EditHost,
  icon: Cloud
};

