import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  ResourceComponentProps,
  ResourceComponentPropsWithId,
  FormDataConsumer
} from "react-admin";

import { types } from "./constants";

export const CreateHost = (props: any) => {
  return (
    <Create {...props}>
      <Form {...props} />
    </Create>
  );
};

export const EditHost = (props: ResourceComponentPropsWithId) => {
  return (
    <Edit title={<Title />} {...props}>
      <Form {...props} />
    </Edit>
  );
};

const Title = ({ record }: any) => {
  return record && `${record.name}`;
};

export const Form = ({ permissions, ...props }: ResourceComponentProps) => {
  return (
    <SimpleForm {...props} redirect="list">
      <TextInput source="name" fullWidth validate={[required()]} />

      <SelectInput
        source="type"
        choices={types}
        fullWidth
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData?.type === "cloud" && (
            <>
              <TextInput
                source="dockerHost"
                fullWidth
                validate={[required()]}
              />

              <TextInput
                multiline
                minRows={4}
                source="dockerTlsCert"
                fullWidth
                validate={[required()]}
              />

              <TextInput
                multiline
                minRows={4}
                source="dockerTlsCaCert"
                fullWidth
                validate={[required()]}
              />

              <TextInput
                multiline
                minRows={4}
                source="dockerTlsKey"
                fullWidth
                validate={[required()]}
              />
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData?.type === "on prem" && (
            <>
              <TextInput
                source="gitlabRunnerTag"
                fullWidth
                validate={[required()]}
              />
              <TextInput source="ipAddress" fullWidth validate={[required()]} />
              <TextInput source="tailnetIpAddress" fullWidth />
            </>
          )
        }
      </FormDataConsumer>

      {/* <ReferenceInput source="version" reference="tags">
        <SelectInput />
      </ReferenceInput> */}
    </SimpleForm>
  );
};
