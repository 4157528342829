import { Box, Card, CardHeader } from "@material-ui/core";
import { DashboardComponent } from "react-admin";
import { adminRole } from "./resources/users/constants";

export const Dashboard: DashboardComponent = props => {
  if ([adminRole].includes(props.permissions?.role)) {
    return (
      <Box>
        <Welcome permissions={props.permissions} />
      </Box>
    );
  }
  return <div />;
};

export const Welcome: React.FC<{ permissions: any }> = ({ permissions }) => {
  return (
    <Card>
      <CardHeader title="Welcome" />
    </Card>
  );
};
